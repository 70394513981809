<template>
  <div>
    <heads :routeUrl="url"></heads>
    <div class="w">
      <!-- 面包屑 -->
      <div class="breadcrumb d-flex align-center">
        <div class="breadcrumb-title d-flex align-center cursor">
          <span></span>
          课程购买（订单结算）
        </div>
      </div>
      <div class="order">
        <ul class="order-td d-flex align-center justify-between">
          <li class="order-tb">课程信息</li>
          <li class="order-tb">金额/元</li>
        </ul>
        <div class="order-item d-flex align-center">
          <div class="order-img">
            <img :src="list.img" alt="" />
          </div>
          <div class="order-c d-flex flex-column justify-between">
            <div class="item-name">
              {{ list.packageName }}
            </div>
            <div class="item-alltime">总课时：{{ list.lessonNum }}</div>
            <div class="item-time">有效期：{{ list.updateTime }}</div>
          </div>
          <div class="pic-list">
            <div v-if="list.lastPrice" class="pic">¥{{ list.price }}</div>
            <div class="pics">
              ¥{{ list.lastPrice ? list.lastPrice : list.price }}
            </div>
          </div>
        </div>
      </div>
      <div class="people d-flex align-center">
        <div class="people-title">个人信息：</div>
        <div class="name d-flex align-center">
          <div class="name-txt">
            <span>*</span>
            真实姓名：
          </div>
          <input
            class="name-input"
            ref="name"
            v-model="name"
            type="text"
            maxlength="11"
          />
        </div>
        <!-- <div class="phone d-flex align-center">
          <div class="phone-txt">
            <span>*</span>
            手机号码：
          </div>
          <input class="phone-input" type="text" maxlength="11" />
        </div> -->
        <div class="tishi">*预留信息，以保障您的购买权益</div>
      </div>
      <div class="manner-title d-flex align-center">支付方式：</div>
      <div class="manner">
        <div class="manner-item d-flex align-center">
          <img
            @click="paymentTab(1)"
            class="manner-checkout"
            src="../../assets/img/manner.png"
            alt=""
            v-if="payType != 1"
          />
          <img
            class="manner-checkout"
            src="../../assets/img/manner_active.png"
            alt=""
            v-if="payType == 1"
          />
          <img class="manner-img" src="../../assets/img/weiixn.png" alt="" />
          <div class="manner-name">微信支付</div>
        </div>
        <div class="manner-item d-flex align-center">
          <img
            @click="paymentTab(3)"
            class="manner-checkout"
            src="../../assets/img/manner.png"
            alt=""
            v-if="payType != 3"
          />
          <img
            @click="paymentTab(3)"
            class="manner-checkout"
            src="../../assets/img/manner_active.png"
            alt=""
            v-if="payType == 3"
          />
          <img
            class="zhifubao-img"
            src="../../assets/img/zhifubao.png"
            alt=""
          />
          <div class="manner-name">支付宝支付</div>
        </div>
      </div>
      <div class="manner-title d-flex align-center">结算信息：</div>
      <div class="settle">
        <div class="settle-num">课程：1</div>
        <div class="settle-pic d-flex align-center justify-between">
          <div class="pic-l">
            课程价格：<span>¥{{ list.payPrice }}</span>
          </div>
          <div class="pic-r">
            应付金额：<span>¥{{ list.payPrice }}</span>
          </div>
        </div>
        <div
          class="settle-btn d-flex align-center justify-center cursor"
          @click="submitBtn"
        >
          立即结算
        </div>
      </div>
    </div>
    <el-dialog
      title="支付"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="d-flex align-center justify-center">
        <div id="qrcode" ref="qrcode"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <!-- <el-button type="primary" @click="dialogVisible = false"
          >支付完成</el-button
        > -->
      </span>
    </el-dialog>
    <footers></footers>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  data() {
    return {
      url: "course",
      id: "",
      list: "",
      name: "",
      dialogVisible: false,
      time: "",
      payType: 1
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetails();
    clearInterval(this.time);
  },
  methods: {
    handleClose(done) {
      let _this = this
      _this.$confirm('确认取消支付？')
        .then(_ => {
          console.log("取消支付")
          clearInterval(_this.time);
          _this.$router.replace('myOrder');
          done();
        })
        .catch(_ => { });
    },
    // getDetails() {
    //   let _this = this;
    //   _this.$api
    //     .queryByOrderId({
    //       orderId: _this.id
    //     })
    //     .then(res => {
    //       console.log(res);
    //       _this.list = res.data;
    //       _this.name = res.data.userName
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    async getDetails(params) {
      let _this = this;
      let data = _this.id
      const res = await this.$ajaxRequest('get', 'queryByOrderId', '', data)
      console.log(res);
      _this.list = res.data;
      _this.name = res.data.userName
    },
    //方式切换
    paymentTab(index) {
      this.payType = index;
    },
    // 定时
    async queryOrderStatus(id) {
      let _this = this;
      let data = id
      const res = await this.$ajaxRequest('get', 'queryOrderStatus', '', data)
      // _this.list = res.data;
      if (res.data.status == 1) {
        console.log(res);
        clearInterval(this.time);
        _this.$message({
          message: res.msg,
          type: "success"
        });
        this.$router.replace("myOrder");
      }
    },
    // 提交
    async create(id) {
      let _this = this;
      let data = {
        orderId: _this.id,
        payType: _this.payType,
        rname: _this.name
      }
      const res = await this.$ajaxRequest('post', 'toPay', data)
      console.log(res.data);
      if (res.code == 200) {
        if (_this.payType == 1) {
          _this.dialogVisible = true;
          console.log(res);
          setTimeout(function () {
            let qrcode = new QRCode("qrcode", {
              width: 200, // 二维码宽度
              height: 200, // 二维码高度
              text: res.data.code_url
            });
          }, 100);
          _this.time = setInterval(() => {
            _this.queryOrderStatus(res.data.orderId);
          }, 5000);
        }
        if (_this.payType == 3) {
          document.querySelector("body").innerHTML = res.data.body;
          const div = document.createElement("div"); // 创建div
          div.innerHTML = res.data.body; // 将返回的form 放入div
          document.body.appendChild(div);
          document.forms[0].submit();
        }
      } else {
        _this.$message.error(res.msg);
      }

    },
    // 提交按钮
    submitBtn() {
      let _this = this;
      let name = _this.$refs.name.value;
      if (name == "") {
        _this.$message.error("请填写真实姓名");
        return false;
      } else {
        _this.create()
      }
    }
  }
};
</script>

<style lang="less" scoped>
.tab_active {
  font-size: 24px !important;
  color: #5f2eff !important;
  &::after {
    display: block !important;
  }
}
#qrcode {
  height: 200px;
  width: 200px;
  text-align: center;
}
// 面包屑
.breadcrumb {
  margin-top: 29px;
  .breadcrumb-title {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    // padding-left: 15px;
    // box-sizing: border-box;
    // border-left: 4px solid #5f2eff;
    span {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 11px;
    }
  }
}
.order {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(191, 191, 191, 0.18);
  border-radius: 4px;
  .order-td {
    height: 50px;
    background: #eeeeee;
    padding-left: 339px;
    padding-right: 46px;
    box-sizing: border-box;
    .order-tb {
      font-size: 16px;
      color: #444444;
    }
  }
  .order-item {
    padding: 24px 46px 27px 24px;
    box-sizing: border-box;
    .order-img {
      width: 140px;
      height: 139px;
      border-radius: 6px;
      overflow: hidden;
    }
    .order-c {
      margin-left: 15px;
      height: 139px;
      .item-name {
        font-size: 18px;
        font-weight: bold;
        color: #444444;
      }
      .item-tea {
        font-size: 14px;
        color: #666666;
      }
      .item-alltime {
        font-size: 14px;
        color: #666666;
      }
      .item-time {
        font-size: 14px;
        color: #999999;
      }
    }
    .pic-list {
      margin-left: auto;
      .pic {
        font-size: 22px;
        font-weight: bold;
        color: #f53444;
        text-decoration: line-through;
      }
      .pics {
        font-size: 22px;
        font-weight: bold;
        color: #f53444;
        // text-decoration: line-through;
      }
    }
  }
}
.people {
  width: 1200px;
  height: 70px;
  background: #eeeeee;
  padding: 0px 40px;
  box-sizing: border-box;
  margin-top: 30px;
  .people-title {
    font-size: 16px;
    color: #444444;
  }
  .name {
    margin-left: 36px;
    .name-txt {
      font-size: 16px;
      color: #444444;
      span {
        color: #f53444;
        font-size: 16px;
      }
    }
    .name-input {
      width: 150px;
      height: 30px;
      background: #ffffff;
      font-size: 16px;
      padding: 0 11px;
      box-sizing: border-box;
    }
  }
  .phone {
    margin-left: 36px;
    .phone-txt {
      font-size: 16px;
      color: #444444;
      span {
        color: #f53444;
        font-size: 16px;
      }
    }
    .phone-input {
      width: 150px;
      height: 30px;
      background: #ffffff;
      font-size: 16px;
      padding: 0 11px;
      box-sizing: border-box;
    }
  }
  .tishi {
    margin-left: 43px;
    font-size: 14px;
    color: #f53444;
  }
}
.manner-title {
  width: 1200px;
  height: 70px;
  background: #eeeeee;
  font-size: 16px;
  color: #444444;
  padding: 0 40px;
  box-sizing: border-box;
  margin-top: 30px;
}
.manner {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(191, 191, 191, 0.18);
  border-radius: 0px 0px 4px 4px;
  padding: 30px 52px;
  box-sizing: border-box;
  .manner-item {
    margin-bottom: 20px;
    .manner-checkout {
      width: 16px;
      height: 16px;
    }
    .manner-img {
      width: 27px;
      height: 29px;
      margin-left: 20px;
      margin-right: 10px;
    }
    .zhifubao-img {
      width: 27px;
      height: 20px;
      margin-left: 20px;
      margin-right: 10px;
    }
    .manner-name {
      font-size: 16px;
      color: #444444;
    }
  }
}
.settle {
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(191, 191, 191, 0.18);
  border-radius: 0px 0px 4px 4px;
  padding: 30px 40px;
  box-sizing: border-box;
  .settle-num {
    font-size: 18px;
    color: #666666;
  }
  .settle-pic {
    margin-top: 24px;
    .pic-l {
      font-size: 18px;
      color: #666666;
      span {
        font-size: 28px;
        font-weight: bold;
        color: #666666;
      }
    }
    .pic-r {
      font-size: 18px;
      color: #666666;
      span {
        font-size: 34px;
        font-weight: bold;
        color: #f53444;
      }
    }
  }

  .settle-btn {
    width: 170px;
    height: 52px;
    background: linear-gradient(-17deg, #ed2e32 1%, #f8a927 100%);
    border-radius: 26px;
    font-size: 24px;
    color: #ffffff;
    margin-left: auto;
    margin-top: 24px;
  }
}
</style>
